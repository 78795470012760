/**
 * Created by vaibhav on 2/4/18
 */
import React, { Component } from "react"
import Helmet from "react-helmet"
import Link from "gatsby-link"

import HomeLogo from "../../components/HomeLogo"

import PageWrapper from "../../layouts/PageWrapper"

const ThankYouPage = () => {
  return <PageWrapper>
      <Helmet>
        <title>Thank you - E.A. Technique</title>
      </Helmet>
      <section className="hero is-fullheight">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column">
                <HomeLogo />
                <h1 className="title" style={{ marginTop: "3rem" }} id="title-line">
                  Contact form
                </h1>
                <h4 className="is-size-4 has-text-weight-bold">
                  Submitted successfully! Thank you.
                </h4>
                <Link to={`/`}>
                  <h4 className="is-size-7 has-text-weight-bold blue">
                    Go back
                  </h4>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container" />
      </section>
    </PageWrapper>
}

export default ThankYouPage
